<template>
    <div>
        <vx-card class="p-0">
            <div class="flex">           
                <h3 class="w-full text-center mb-2"> {{parametros.dadosVeiculo}} </h3>
            </div>
            <div class="flex">           
                <flat-pickr v-model="parametros.dataInicio" :config="configDateYMDPicker" placeholder="Data Início"/>          
                <flat-pickr v-model="parametros.dataFim" :config="configDateYMDPicker" placeholder="Data Fim"/>            
                <vs-button class="ml-2" icon="search" color="primary" @click="fetchCobertura" :disabled="!parametros.dataInicio || !parametros.dataFim" />            
            </div>
       </vx-card>
       <div class="flex">
            <vx-card title="Vivo 2g" card-background="dark" content-color="#fff" title-color="#fff" v-if="sumario" style="margin: 2px;">                            
                <div class="flex items-center">
                    <h3 class="font-bold text-white">{{sumario.vivo2g}}</h3><small>/{{sumario.totalCidades}}</small>
                </div>                            
            </vx-card>
            <vx-card title="Claro 2g" card-background="dark" content-color="#fff" title-color="#fff" v-if="sumario" style="margin: 2px;">                            
                <div class="flex items-center">
                    <h3 class="font-bold text-white">{{sumario.claro2g}}</h3><small>/{{sumario.totalCidades}}</small>
                </div>                            
            </vx-card>
            <vx-card title="TIM 2g" card-background="dark" content-color="#fff" title-color="#fff" v-if="sumario" style="margin: 2px;">                            
                <div class="flex items-center">
                    <h3 class="font-bold text-white">{{sumario.tim2g}}</h3><small>/{{sumario.totalCidades}}</small>
                </div>                            
            </vx-card>
            <vx-card title="Oi 2g" card-background="dark" content-color="#fff" title-color="#fff" v-if="sumario" style="margin: 2px;">                            
                <div class="flex items-center">
                    <h3 class="font-bold text-white">{{sumario.oi2g}}</h3><small>/{{sumario.totalCidades}}</small>                                        
                </div>                            
            </vx-card>
       </div> 
       <vs-table :data="listaCoberturaMunicipios" class="tabelaComScroll2 w-full" noDataText="..." search stripe>
            <template slot="thead">
                <vs-th class="p-0">UF</vs-th>
                <vs-th class="p-0">Municipio </vs-th>            
                <vs-th class="p-0">VIVO 2g</vs-th>            
                <vs-th class="p-0">CLARO 2g</vs-th>
                <vs-th class="p-0">TIM 2g </vs-th>
                <vs-th class="p-0">OI 2g </vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                    <vs-td class="p-0">{{tr.nomeUF}} </vs-td>
                    <vs-td class="p-0">{{tr.nomeMunicipio}} </vs-td>
                    <vs-td class="p-0 "><feather-icon v-if="tr.vivo2g" icon="CheckCircleIcon" svgClasses="w-5 h-5 text-success" /></vs-td>
                    <vs-td class="p-0 "><feather-icon v-if="tr.claro2g" icon="CheckCircleIcon" svgClasses="w-5 h-5 text-success" /></vs-td>
                    <vs-td class="p-0 "><feather-icon v-if="tr.tim2g" icon="CheckCircleIcon" svgClasses="w-5 h-5 text-success" /></vs-td>
                    <vs-td class="p-0 "><feather-icon v-if="tr.oi2g" icon="CheckCircleIcon" svgClasses="w-5 h-5 text-success" /> </vs-td>
                </vs-tr>
            </template>
       </vs-table>
    </div>
    </template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';

export default {
    components: {
        flatPickr      
    },
    props: {        
        parametros: {
            type: Object,
            required: true            
        },
    },
    data() {
        return {            
            listaCoberturaMunicipios: [],
            sumario: null,
            configDateYMDPicker: {          
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "Y/m/d", 
                altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
                locale: PortugueseLocale
            },
        }        
    },    
    beforeMount(){
        this.listaCoberturaMunicipios = [];
        this.sumario = null;
    },
    methods: {
        fetchCobertura() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.parametros.veiculoSelecionado.idVeiculo);
            params.append('idCliente', this.parametros.clienteSelecionado.id);            
            params.append('dataInicio', this.parametros.dataInicio);
            params.append('dataFim', this.parametros.dataFim);
            
            axios.post("/LocalizarVeiculosParaCentralMonitoramento", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaCoberturaMunicipios = response.data.lista;         
                    this.sumario = response.data.sumario;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchCobertura - " + error); 
            })    
                                
        },
    },
    mounted() {
        this.fetchCobertura();
    }
}
</script>