<template>
  <div>
     <vs-popup fullscreen title="Listar Rastreamentos" :active.sync="popUpListarRastreamentos.exibir" v-if="popUpListarRastreamentos.exibir">
        <ListarRastreamentos :veiculo="popUpListarRastreamentos.veiculo" />
    </vs-popup>
    <vs-popup :title="`${popUpHistoricoEventos.titulo} - ${popUpHistoricoEventos.veiculo.placa}`" :active.sync="popUpHistoricoEventos.exibir" v-if="popUpHistoricoEventos.veiculo">
       <div class="flex">
          <flat-pickr v-model="popUpHistoricoEventos.dataHoraPanico" :config="configDatePicker" placeholder="Data"/>
          <vs-button class="ml-2" icon="close" color="primary" @click="popUpHistoricoEventos.dataHoraPanico = null"/>
          <vs-button class="ml-2" icon="search" color="primary" @click="fetchHistorico()"/>
          <vs-button class="ml-2" icon="print" color="primary" v-if="false"></vs-button> 
       </div>
       <vs-table :data="dadosDaGridHistorico" class="tabelaComScroll mt-2" pagination noDataText="Nenhum evento encontrado">
          <template slot="thead">
              <vs-th>Evento</vs-th>
              <vs-th>Data</vs-th>            
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td><small>{{tr.evento}}</small></vs-td>                
                <vs-td><small>{{tr.dataHora}}</small> </vs-td>
              </vs-tr>
          </template>
       </vs-table>
    </vs-popup>
    <vs-popup title="Coberturas Operadoras" :active.sync="popUpCobertura.exibir" v-if="popUpCobertura.exibir">
        <CoberturaOperadoras :parametros="popUpCobertura" v-if="popUpCobertura.exibir" />       
    </vs-popup>

    <div style="position:absolute; height: calc(100vh - 55px); width: 50%; right:0px; z-index:2">                      
        <GoogleMapConcept :mapa="map" ref="gmc"></GoogleMapConcept>                      
    </div>

    <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6"> 
            <vx-card class="mr-4 p-0">
                <label class="vs-input--label">Cliente:</label>
                <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"      
                    @input="click_PesquisarVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>                  
                <div class="flex mt-1 items-center" v-if="clienteSelecionado">
                    <div class="w-full"> 
                        <label class="vs-input--label">Divisão:</label> 
                        <v-select                       
                            v-if="clienteSelecionado"
                            label="nome"                                
                            :options="listaDivisoesVeiculo" 
                            v-model="divisaoSelecionada"    
                            @input="exibirVeiculosListados()"                  
                            class="vs-input--label w-full" >
                        </v-select> 
                    </div>
                    <div class="w-full ml-2"> 
                        <label class="vs-input--label" >Termo:</label> 
                        <vx-input-group>     
                            <input class="vs-input--input w-full" type="text"  style="min-width: 172px; line-height: 24px;" name="txtTermoPesquisa" id="txtTermoPesquisa" ref="txtTermoPesquisa"  v-on:keyup.13="pesquisarPorTermo($refs.txtTermoPesquisa.value)"/> 
                            <template slot="append">
                                <div class="append-text btn-addon">
                                    <vs-button color="dark" size="small" icon-pack="feather" icon="icon-search" @click="pesquisarPorTermo($refs.txtTermoPesquisa.value)"></vs-button>                                                
                                </div>
                            </template>
                        </vx-input-group>
                    </div>                    
                    <div class="flex mt-6 ml-2">
                        <vx-tooltip text="Atualizar os veículos">                
                            <vs-button size="small" class="ml-auto" color="primary" type="filled" v-if="listaVeiculos.length > 0 " icon-pack="feather" icon="icon-refresh-cw" @click="fetchListaVeiculos()"></vs-button>
                        </vx-tooltip>
                        <h6 class="ml-2 mt-2 mr-2" v-if="listaVeiculos.length > 0 " style="white-space: nowrap;"> {{filteredVeiculos.length}} / {{listaVeiculos.length}}</h6>
                    </div>
                </div>

            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">           
            <vs-alert class="mt-4 mr-4" color="danger" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>
    <vs-row class="mt-1">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6"> 
            <vs-table :data="filteredVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." stripe v-model="veiculoSelecionado" @selected="mostrarNoMapa">                
                <template slot="thead">
                    <vs-th class="p-0">Veículo</vs-th>
                    <vs-th class="p-0">Status </vs-th>            
                    <vs-th class="p-0">Conexão </vs-th>            
                    <vs-th class="p-0">GSM</vs-th>
                    <vs-th class="p-0">Ação </vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                        <vs-td class="p-0"><small @click="mostrarNoMapa(tr)">{{tr.modelo}} <span class="font-bold">{{tr.placa}}</span> </small> <br/><small>{{tr.labelVeiculo}}</small></vs-td>
                        <vs-td class="p-0">
                            <vx-tooltip v-bind:text="'Veículo ligado  - voltagem ' + tr.ultimaVoltagem + 'v'">                
                                <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-success" class="ml-2"  v-if="tr.ignicao === 'LIGADO' && tr.velocidadeAtual > 0" />
                            </vx-tooltip>
                            <vx-tooltip v-bind:text="'Veículo ligado e parado à ' + tr.tempoParado +' - ' + tr.ultimaVoltagem + 'v'">                
                                <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-warning" class="ml-2"  v-if="tr.ignicao === 'LIGADO' && tr.velocidadeAtual === 0" />
                            </vx-tooltip>
                            <vx-tooltip v-bind:text="'Veículo desligado à ' + tr.tempoParado + ' - ' + tr.ultimaVoltagem + 'v' ">                
                                <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-danger" class="ml-2"  v-if="tr.ignicao === 'DESLIGADO'" />
                            </vx-tooltip>
                            <vx-tooltip text="Veículo bloqueado">                
                                <feather-icon icon="LockIcon" svgClasses="w-3 h-3 stroke-current text-danger" class="ml-2"  v-if="tr.possuiBloqueio && tr.isBloqueado" />
                            </vx-tooltip>
                            <vx-tooltip text="Veículo desbloqueado">                
                                <feather-icon icon="UnlockIcon" svgClasses="w-3 h-3 stroke-current" class="ml-2" v-if="tr.possuiBloqueio && !tr.isBloqueado" />
                            </vx-tooltip>
                        </vs-td>
                        <vs-td class="p-0">                
                            <vx-tooltip :text="'Comunicação atualizada às ' + tr.dataUltimoPacoteRecebido">                
                                <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-success" v-if=" tr.status === 'ON'" />
                            </vx-tooltip>  
                            <vx-tooltip v-bind:text="'Sem transmissão há ' + tr.tempoSemGPRS" >                
                                <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-danger" v-if="tr.status === 'OFF'" />
                            </vx-tooltip>                                            
                            <vx-tooltip text="Sem alimentação externa">                
                                <feather-icon icon="ZapOffIcon" svgClasses="w-4 h-4 stroke-current text-danger"  v-if="tr.semAlimentacaoExterna" />
                            </vx-tooltip>                                                    
                        </vs-td>
                        <vs-td class="p-0">
                            <vx-tooltip :text="'Multioperadora:' + tr.operadoraGSMConectada ">                
                                <small>{{tr.operadoraGSM}} <br/> {{tr.numeroGSM}}</small>
                            </vx-tooltip>
                        </vs-td>
                        <vs-td class="p-0">
                            <div class="flex">
                                 <vx-tooltip text="Clique para bloquear o veículo">   
                                    <feather-icon icon="LockIcon" svgClasses="w-4 h-4 stroke-current text-danger" class="mr-2" v-if="tr.possuiBloqueio" @click.stop="bloquear(tr, 'BLOQUEAR')"/>
                                </vx-tooltip>
                                
                                <vx-tooltip text="Clique para acionar a saida 2">   
                                    <feather-icon icon="ToggleLeftIcon" svgClasses="w-4 h-4 stroke-current text-success" class="mr-2" v-if="tr.possuiSaida2" @click.stop="abrirPopUpInformacoes(tr)"/>
                                </vx-tooltip>
                                <vx-tooltip text="Clique para visualizar o histórico do eventos">   
                                    <feather-icon icon="SearchIcon" svgClasses="w-4 h-4 stroke-current text-warning" class="mr-2" @click.stop="abrirPopUpHistoricoEventos(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Clique para visualizar o cobertura operadoras">   
                                    <feather-icon icon="RadioIcon" svgClasses="w-4 h-4 stroke-current text-success"  @click.stop="abrirPopUpCobertura(tr)" />
                                </vx-tooltip>
                            </div>
                            <div class="flex">
                                <vx-tooltip text="Clique para desbloquear o veículo">   
                                    <feather-icon icon="UnlockIcon" svgClasses="w-4 h-4 stroke-current text-warning" class="mr-2" v-if="tr.possuiBloqueio"  @click.stop="desbloquear(tr, 'DESBLOQUEAR')"/>
                                </vx-tooltip>
                                <vx-tooltip text="Clique para desacionar a saida 2">   
                                    <feather-icon icon="ToggleRightIcon" svgClasses="w-4 h-4 stroke-current text-warning" class="mr-2" v-if="tr.possuiSaida2" @click.stop="abrirPopUpHistorico(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Clique para visualizar o histórico do panicos">   
                                    <feather-icon icon="SearchIcon" svgClasses="w-4 h-4 stroke-current text-danger" class="mr-2" @click.stop="abrirPopUpHistoricoPanico(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Clique para Listar Rastreamentos">   
                                    <feather-icon icon="SearchIcon" svgClasses="w-4 h-4 stroke-current text-success" class="mr-2" @click="abrirPopUpListarRastreamentos(tr)" v-show="!isMonitor" />
                                </vx-tooltip>
                                <vx-tooltip text="Abrir Ordem de Serviço">   
                                    <feather-icon icon="FileTextIcon" svgClasses="w-4 h-4 stroke-current text-success" class="mr-2" @click="abriOrdemServico(tr)" v-show="!isMonitor"/>
                                </vx-tooltip>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from "@/axios.js"
import generico from '@/generico.js'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import CoberturaOperadoras from '@/views/components/CoberturaOperadoras'
import ListarRastreamentos from '@/views/components/ListarRastreamentos'
import 'vue-select/dist/vue-select.css';

export default {
    components: {
        flatPickr,
        CoberturaOperadoras,
        ListarRastreamentos
    },
    data() {
        return {
            txtTermoPesquisa: null,
            termoPesquisa: null,
            popUpCobertura: {
                exibir: false,
                dataInicio: null,
                dataFim: null,
                veiculoSelecionado: null,                
                dadosVeiculo: null,
                clienteSelecionado: null
            },
            map: null,
            exibirMensagemNenhumDadoEncontrado: false,
            listaClientes: [],
            listaVeiculos: [],
            clienteSelecionado: null,
            dadosDaGridHistorico: [],
            popUpHistoricoEventos: {
                exibir: false,
                veiculo: null,
                dataHoraPanico: null,
                titulo: null,
                isPanico : false,
            },
            configDatePicker: {          
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "U", 
                altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
                locale: PortugueseLocale
            },           
            veiculoSelecionado: [],
            pollingIntervalObj: null,
            infowindow: null,
            markerInfowindow: null,
            listaContentStringInfowindow: new Map(),
            listaDivisoesVeiculo: [],
            divisaoSelecionada: null,
            fetchingListaVeiculos : false,
            popUpListarRastreamentos : {
                exibir: false,
                veiculo: null,
            }, 
            publicPath: process.env.BASE_URL
        }
    },
    computed: {
        filteredVeiculos: function () {
            var self = this;
            var arrayRetorno = self.listaVeiculos;  

            arrayRetorno = arrayRetorno.filter(function (veiculo) {
                 let termoUC = ( self.termoPesquisa != null ? self.termoPesquisa.toUpperCase() : null);
                 return (termoUC ? (veiculo.modelo.toUpperCase().indexOf(termoUC) != -1 ||                            
                                    veiculo.condutor.toUpperCase().indexOf(termoUC) != -1 || 
                                    (veiculo.labelVeiculo && veiculo.labelVeiculo.toUpperCase().indexOf(termoUC) != -1)|| 
                                    veiculo.placa.toUpperCase().indexOf(termoUC) != -1): true)
            });

            if (self.divisaoSelecionada != null) {
                arrayRetorno = arrayRetorno.filter(function (veiculo) {
                    return veiculo.hasOwnProperty('idDivisao') && self.divisaoSelecionada.id === veiculo.idDivisao;
                })
                
            }

            return arrayRetorno;
        },
        isMonitor(){
            return this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO";                
        }
    },
    methods: {  
        pesquisarPorTermo(valorTermo){
            this.termoPesquisa = valorTermo;            
            this.exibirVeiculosListados();
        },
        abriOrdemServico(tr){
            let _self = this;
            this.$router.push({
                    name: "cadastro-ordem-servico-editar",
                    params: {
                    preDados: {idCliente: _self.clienteSelecionado.id, placa: tr.placa }
                    }
            })
        },     
        abrirPopUpListarRastreamentos(tr) {
            this.popUpListarRastreamentos.exibir = true;
            this.popUpListarRastreamentos.veiculo = tr;
        },  
        abrirPopUpCobertura(tr) {            
            this.popUpCobertura.veiculoSelecionado = tr;
            this.popUpCobertura.clienteSelecionado = this.clienteSelecionado;
            this.popUpCobertura.dadosVeiculo = tr.modelo + " " + tr.placa + " " + tr.labelVeiculo;
            if (!this.popUpCobertura.dataInicio) {
                var date = new Date();
                let firstDay = new Date(date.getFullYear(), date.getMonth()-1, 1);
                let lastDay = new Date();
                this.popUpCobertura.dataInicio = firstDay.getFullYear() + "/" + generico.zeroPad(firstDay.getMonth() + 1, 2) + "/" + generico.zeroPad(firstDay.getDate(), 2);
                this.popUpCobertura.dataFim = lastDay.getFullYear() + "/" + generico.zeroPad(lastDay.getMonth() + 1, 2) + "/" + generico.zeroPad(lastDay.getDate(), 2);
            }
            this.popUpCobertura.exibir = true;
        },
        mostrarNoMapa(veiculo) {
            if (veiculo && veiculo.latitude) {
                var location = this.$refs.gmc.getLatLng(veiculo.latitude, veiculo.longitude);
                this.map.setCenter(location);
                this.map.setZoom(18);
            }
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },
        abrirPopUpHistoricoPanico(tr) {
            this.popUpHistoricoEventos.titulo = "Histórico de Pânicos"
            this.popUpHistoricoEventos.isPanico = true;
            this.popUpHistoricoEventos.exibir = true;
            this.popUpHistoricoEventos.veiculo = tr;
            this.popUpHistoricoEventos.dataHoraPanico = String(new Date().getTime() / 1000);
            this.fetchHistoricoPanicos();
        },
        abrirPopUpHistoricoEventos(tr) {
            this.popUpHistoricoEventos.titulo = "Histórico de Eventos"
            this.popUpHistoricoEventos.isPanico = false;
            this.popUpHistoricoEventos.exibir = true;
            this.popUpHistoricoEventos.veiculo = tr;
            this.popUpHistoricoEventos.dataHoraPanico = String(new Date().getTime() / 1000);
            this.fetchHistoricoEventos();
        },
        click_PesquisarVeiculos() {
            
            clearInterval(this.pollingIntervalObj);
            this.listaVeiculos = null;
            this.listaVeiculos = [];
            this.$refs.gmc.deleteMarkers();
            
            if (!this.clienteSelecionado) {                
                this.listaVeiculos = [];
                return;
            }

            this.$vs.loading();
            this.pollingIntervalObj = setInterval(() => {
                this.fetchListaVeiculos();
            }, 60000);
            this.fetchListaDivisaoVeiculos();
            this.fetchListaVeiculos();
        },
        fetchListaVeiculos() { 
            if (this.fetchingListaVeiculos) {
                return;
            }

            this.fetchingListaVeiculos = true;
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('cpfcnpj', this.clienteSelecionado.CNPJCPF);
            params.append('idUsuarioMonitorPanico', this.$store.state.AppActiveUser.id);
            
            axios.post("/RelatorioCliente", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.fetchingListaVeiculos = false;
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    if (this.listaVeiculos.length === 0) {
                        this.listaVeiculos = response.data.listaVeiculos;  
                        if (this.listaVeiculos && this.listaVeiculos.length > 0) {
                            this.listaVeiculos.forEach((v) => {     
                                if (v.latitude) {                                                                     
                                    this.addMarkerVeiculo(v);
                                }
                            });   

                            this.$refs.gmc.ajustarZoom(this.map);  
                        }
                    } else {
                        this.atualizarArrayVeiculos(response.data.listaVeiculos);                        
                    }
                }
            })
            .catch((error) => { 
                this.fetchingListaVeiculos = false;
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
            this.fetchingListaVeiculos = false;
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
           
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        bloquear(tr) {         
            this.veiculoSelecionado = [ tr ];   
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja bloquear o veículo ' +  tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.bloquearRequest
            });      

        },
        bloquearRequest() {
            this.enviarBloqueioDesbloqueio(this.veiculoSelecionado[0], 'BLOQUEAR');
        },
        desbloquear(tr) {            
            this.veiculoSelecionado = [ tr ];   
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja desbloquear o veículo ' +  tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.desbloquearRequest
            });            
        },
        desbloquearRequest() {
            this.enviarBloqueioDesbloqueio(this.veiculoSelecionado[0], 'DESBLOQUEAR');
        },
        enviarBloqueioDesbloqueio(evento, acao) {
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');

            params.append('serialModulo', evento.serialModulo);
            params.append('operadoraGSM', evento.operadoraGSM);
            params.append('codigoModulo', evento.codigoModulo);
            params.append('marcaModulo', evento.marcaModulo);
            params.append('tipoComandoBloqueioDesbloqueio', acao);
            params.append('idUsuario', this.$store.state.AppActiveUser.id);

            axios.post("/EnviarComandoBloqueioDesbloqueio", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    if (response.data.codigo === 0) {
                    this.$vs.notify({
                        title: "Mensagem", text: response.data.mensagem,
                        iconPack: 'feather', icon: 'icon-info-circle', color: 'success' });
                    }  else {
                    this.$vs.notify({
                        title: "Mensagem", text: response.data.mensagem,
                        iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
                    }              
                }
            })
            .catch((error) => {                 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
            })  
        },
        fetchHistorico(){
            if (this.popUpHistoricoEventos.isPanico) {
                this.fetchHistoricoPanicos();
            } else {
                this.fetchHistoricoEventos();
            }
        },
        fetchHistoricoPanicos() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpHistoricoEventos.veiculo.idVeiculo);
            if (this.popUpHistoricoEventos.dataHoraPanico) {
                params.append('dataHoraPanico', this.popUpHistoricoEventos.dataHoraPanico*1000);
            }

            axios.post("/ListarHistoricoPanicoVeiculo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.dadosDaGridHistorico = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },
        fetchHistoricoEventos() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpHistoricoEventos.veiculo.idVeiculo);
            if (this.popUpHistoricoEventos.dataHoraPanico) {
                params.append('dataHora', this.popUpHistoricoEventos.dataHoraPanico*1000);
            }

            axios.post("/ListarAvisosVeiculoClientePorData", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.dadosDaGridHistorico = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },
        addMarkerVeiculo(veiculo) {
            var location = this.$refs.gmc.getLatLng(veiculo.latitude, veiculo.longitude);
            let cssLabel =""
            var dadosVeiculo = veiculo.marca + " " + veiculo.modelo;
            var enderecoAtual = veiculo.enderecoAtual;
            var statusGPRS = veiculo.status;
            var dataAtualizacao = veiculo.dataUltimaAtualizacao;
            var ignicao = veiculo.ignicao;
            var condutorVeiculo = veiculo.condutor;

            var tempoParadaOuVelocidade = "";
            if (ignicao == "LIGADO") {
                if (veiculo.velocidadeAtual > 0) {
                    cssLabel = "labelLigadoEmMovimento";
                    tempoParadaOuVelocidade = veiculo.velocidadeAtual + " KM/h";
                } else {
                    cssLabel = "labelLigadoEmParado";
                    tempoParadaOuVelocidade = "Parado por " + veiculo.tempoParado;
                }
            } else {
                cssLabel = "labelDesligado";
                tempoParadaOuVelocidade = "Parado por " + veiculo.tempoParado;
            }    
            
            
            var marker1 = new window.MarkerWithLabel({
                position: location,
                labelContent: veiculo.placa,
                title: veiculo.placa,
                map: this.map,                   
                labelAnchor: new window.google.maps.Point(30, 42),       
                labelStyle: {opacity: 1} ,
                labelClass: cssLabel,
                icon: { url: this.publicPath + 'imagens/localizacaoCarro.png'}   
            });  
                        
            var marcadores = this.$refs.gmc.getMarcadores();
            marcadores.push(marker1);  
            
            let self_ = this;

            window.google.maps.event.addListener(marker1, 'click', function (event) {
                /* eslint-disable no-console */  console.log(event);
                var contentStringInfowindow = self_.listaContentStringInfowindow.get(marker1.getTitle());

                if (contentStringInfowindow != null && contentStringInfowindow != "") {
                    self_.abrirInfowindow(marker1, contentStringInfowindow);
                } else {
                    self_.listaContentStringInfowindow.set(marker1.getTitle(), self_.montarContentStringInfoWindow(dadosVeiculo, condutorVeiculo, tempoParadaOuVelocidade, ignicao, dataAtualizacao, statusGPRS, enderecoAtual, marker1));
                    self_.abrirInfowindow(marker1, self_.listaContentStringInfowindow.get(marker1.getTitle()));
                }
            });

            if (this.markerInfowindow != null) {
                if (marker1.getTitle() == this.markerInfowindow.getTitle()) {
                    window.google.maps.event.trigger(marker1, 'click');
                }
            }
            
            return marker1;
        },
        alterarVeiculoNoMapa(veiculo) {
            var latitude = veiculo.latitude;
            var longitude = veiculo.longitude;
            var velocidade = veiculo.velocidadeAtual;
            var tempoParada = veiculo.tempoParado;
            var ignicao = veiculo.ignicao;
            var dataAtualizacao = veiculo.dataUltimaAtualizacao;
            var statusGPRS = veiculo.status;
            var tituloMarker = veiculo.placa + '\n' + veiculo.marca + ' ' + veiculo.modelo + '\n' + veiculo.condutor;
            var enderecoAtual = veiculo.enderecoAtual;
            var condutorVeiculo = veiculo.condutor;
            var dadosVeiculo = veiculo.marca + " " + veiculo.modelo;
            
            var marcadores = this.$refs.gmc.getMarcadores();

            if (marcadores.length > 0) {
                for (var i = 0; i < marcadores.length; i++) {
                    if (marcadores[i].getTitle() === tituloMarker) {
                        var tempoParadaOuVelocidade = "";
                        if (ignicao === "LIGADO") {
                            if (velocidade > 0) {
                                marcadores[i].labelClass = "labelLigadoEmMovimento";
                                tempoParadaOuVelocidade = velocidade + " KM/h";
                            } else {
                                marcadores[i].labelClass = "labelLigadoEmParado";
                                tempoParadaOuVelocidade = "Parado por " + tempoParada;
                            }
                        } else {
                            marcadores[i].labelClass = "labelDesligado";
                            tempoParadaOuVelocidade = "Parado por " + tempoParada;
                        }

                        var latlng = this.$refs.gmc.getLatLng(parseFloat(latitude), parseFloat(longitude));

                        marcadores[i].setPosition(latlng);
                        try {
                            if (marcadores[i].label.getProjection() != null) {
                                marcadores[i].label.setStyles();
                            }
                        } catch (e) { console.log(e) }

                         if (this.markerInfowindow != null && marcadores[i].getTitle() == this.markerInfowindow.getTitle() && document.getElementById("pCondutorVeiculo")) {
                            document.getElementById("pCondutorVeiculo").innerHTML = "<p>" + condutorVeiculo + "</p>";
                            document.getElementById("pTempoParadaOuVelocidade").innerHTML = "<p>" + tempoParadaOuVelocidade + "</p>";
                            document.getElementById("divDataAtualizacao").innerText = "Atualizado às " + dataAtualizacao;
                            document.getElementById("spanGPRS").className = "glyphicon glyphicon-signal " + statusGPRS;
                            document.getElementById("spanGPRS").title = statusGPRS;
                            document.getElementById("spanIgnicao").className = "glyphicon glyphicon-cog " + ignicao;
                            document.getElementById("spanIgnicao").title = ignicao;
                            document.getElementById("divEndereco").innerHTML = "<p>" + veiculo.enderecoAtual + "</p>";
                        }

                        this.listaContentStringInfowindow.set(marcadores[i].getTitle(), this.montarContentStringInfoWindow(dadosVeiculo, condutorVeiculo, tempoParadaOuVelocidade, ignicao, dataAtualizacao, statusGPRS, enderecoAtual, marcadores[i]));
                       
                        return;
                    }
                }
            }
        },
        abrirInfowindow(marcador, contentString) {
            if (this.infowindow == null) {
                this.infowindow = new window.google.maps.InfoWindow({
                    pixelOffset: new window.google.maps.Size(0, -15),
                });

                let self_ = this;

                window.google.maps.event.addListener(this.infowindow, 'closeclick', function () {
                    self_.listaContentStringInfowindow.set(marcador.getTitle(), "");
                    self_.markerInfowindow = null;
                });
            }

            this.infowindow.setContent(contentString);
            this.infowindow.open(this.map, marcador);

            this.markerInfowindow = null;
            this.markerInfowindow = marcador;
        },
        montarContentStringInfoWindow(dadosVeiculo, condutorVeiculo, tempoParadaOuVelocidade, ignicao, dataAtualizacao, statusGPRS, enderecoAtual, marcador) {
            var contentString = '<div id="content" style="width:300px">' +
                '<div id="siteNotice"></div>' +
                '<h3 style="color: #000000" id="divDadosVeiculo">' + dadosVeiculo + '</h3>' +
                '<div id="bodyContent">' +
                '<div class="section 1">' + '<p id="pCondutorVeiculo">' + condutorVeiculo + '</p>'
                + '<p class="right">GPRS: &nbsp;&nbsp;&nbsp; <span id="spanGPRS" class="glyphicon glyphicon-signal ' + statusGPRS + '" title="' + statusGPRS + '"></span></p></div>' +
                '<div class="section 2">' + '<p id="pTempoParadaOuVelocidade">' + tempoParadaOuVelocidade + '</p>'
                + '<p class="right">Igni&ccedil;&atilde;o: <span id="spanIgnicao" class="glyphicon glyphicon-cog ' + ignicao + '" title="' + ignicao + '"></span></p>' + '</div>' +
                '<div class="section adr" id="divEndereco"><p> ' + enderecoAtual + ' </p></div>' +
                '<div class="section 4"><a href="javascript:' + this.getClickExibirStreetView(marcador) + '" class="btn">' + 'Street View</a><a href="javascript:click_AbrirPopUpCriarPoI(' + marcador.getPosition().lat() + "," + marcador.getPosition().lng() + ')" class="btn right">PT Interesse</a></div>'
                + '<p class="lblDataAtualizacao"> <a href="javascript:' + this.getClickAbrirGoogleMaps(marcador) + '" id="divDataAtualizacao"> Atualizado às ' + dataAtualizacao + '</a></p>' + '</div>' + '</div>';
            return contentString;
        },
        getClickAbrirGoogleMaps(marcador) {
            return "window.open('https://www.google.com/maps/search/?api=1&query=" + marcador.getPosition().lat() + "," + marcador.getPosition().lng() + "', '_blank')";
        },
        getClickExibirStreetView(marcador) {
            return "window.open('https://www.google.com/maps/@?api=1&map_action=pano&heading=-45&viewpoint=" + marcador.getPosition().lat() + "," + marcador.getPosition().lng() + "','_blank')";
        },
        atualizarVeiculo(va, vn){
            let _self = this;
            return new Promise(function() {
                if (va.placa === vn.placa) {

                    if (va.condutor !== vn.condutor) { va.condutor = vn.condutor; }

                    if ((va.latitude != vn.latitude) || (va.longitude != vn.longitude)) {										                  
                        va.latitude = vn.latitude;
                        va.longitude = vn.longitude;
                    }
                    
                    if (va.ignicao !== vn.ignicao) {
                        va.ignicao = vn.ignicao;
                        va.velocidadeAtual = vn.velocidadeAtual;
                        va.tempoParado = vn.tempoParado;
                    }

                    if (va.velocidadeAtual !== vn.velocidadeAtual) {
                        va.velocidadeAtual = vn.velocidadeAtual;
                        va.tempoParado = vn.tempoParado;
                    }

                    if (va.dataUltimaAtualizacao !== vn.dataUltimaAtualizacao) {  va.dataUltimaAtualizacao = vn.dataUltimaAtualizacao; }
                    if (va.dataUltimoPacoteRecebido !== vn.dataUltimoPacoteRecebido) {  va.dataUltimoPacoteRecebido = vn.dataUltimoPacoteRecebido; }
                    
                    if (va.tempoSemGPRS !== vn.tempoSemGPRS) { va.tempoSemGPRS = vn.tempoSemGPRS; }
                    if (va.tempoParado !== vn.tempoParado) { va.tempoParado = vn.tempoParado; }
                    if (va.statusGPRS !== vn.statusGPRS) { va.status = vn.status; }
                    if (va.isBloqueado !== vn.isBloqueado) { va.isBloqueado = vn.isBloqueado; }
                    if (va.ultimoOdometro !== vn.ultimoOdometro) { va.ultimoOdometro = vn.ultimoOdometro; }

                    if (vn.hasOwnProperty("labelVeiculo")) {
                        va.labelVeiculo = vn.labelVeiculo;
                    } else  if (va.hasOwnProperty("labelVeiculo")) {
                        delete va.labelVeiculo;
                    }

                    if (va.enderecoAtual !== vn.enderecoAtual) { va.enderecoAtual = vn.enderecoAtual; }
                    if (va.ultimaVoltagem !== vn.ultimaVoltagem) { va.ultimaVoltagem = vn.ultimaVoltagem; }
                    if (va.semAlimentacaoExterna !== vn.semAlimentacaoExterna) { va.semAlimentacaoExterna = vn.semAlimentacaoExterna; }

                    if (vn.hasOwnProperty("nivelBateriaModulo")) {                
                        va.nivelBateriaModulo = vn.nivelBateriaModulo;
                    } else if (va.hasOwnProperty("nivelBateriaModulo")) {  
                        delete va.nivelBateriaModulo;
                    }

                    if (vn.hasOwnProperty("nivelBateriaModuloBkp")) {                
                        va.nivelBateriaModuloBkp = vn.nivelBateriaModuloBkp;
                    } else if (va.hasOwnProperty("nivelBateriaModuloBkp")) {  
                        delete va.nivelBateriaModuloBkp;
                    }            
                    
                    if (vn.latitude && vn.longitude) {
                        _self.alterarVeiculoNoMapa(vn);
                    }
                
                }
            });
        },
        async atualizarArrayVeiculos(pVeiculos) {
            if (Array.isArray(this.listaVeiculos) && this.listaVeiculos.length ) {      
                try {
                    this.listaVeiculos.forEach((va) => {                
                        pVeiculos.forEach((vn) => {        
                               this.atualizarVeiculo(va, vn) ;
                        });
                    });
                } catch(erro1){
                    /* eslint-disable no-console */  console.log(erro1);
                    /* eslint-disable no-console */  console.log("novosDados=" + pVeiculos);
                }
            } else {      
                this.listaVeiculos = pVeiculos;
            }
        },
        fetchListaDivisaoVeiculos() {
            const params = new URLSearchParams();
            params.append('idCliente', this.clienteSelecionado.id);

            axios.post("/rest/DivisaoVeiculo/Listar", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                if (response.data.Resultado.quantidade > 1) {
                  this.listaDivisoesVeiculo = response.data.Resultado.lista.item;
                } else if (response.data.Resultado.quantidade === 1) {
                    this.listaDivisoesVeiculo = [];
                    this.listaDivisoesVeiculo.push(response.data.Resultado.lista.item);
                } else {
                    this.listaDivisoesVeiculo = [];
                }    
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fetch Lista Marcas Veiculos " + error);        
            })      
        },   
        exibirVeiculosListados() {
            var marcadores = this.$refs.gmc.getMarcadores();

            if (marcadores.length > 0) {
                var markerSelecionado = null;
                var bounds = null;
                var i = 0;
                for (i = 0; i < marcadores.length; i++) {
                    marcadores[i].setVisible(false);                                                    
                }
                
                for (i = 0; i < marcadores.length; i++) {
                    for (var j = 0; j < this.filteredVeiculos.length; j++) {
                        if (marcadores[i].getTitle() === this.filteredVeiculos[j].placa) {
                            // Verificar se está exibivel na grid
                            if (!this.filteredVeiculos[j].hasOwnProperty('verNoMapa') || this.filteredVeiculos[j].verNoMapa) {
                                marcadores[i].setVisible(true);
                                marcadores[i].setMap(this.map);
                                if (bounds == null) {
                                    bounds = new window.google.maps.LatLngBounds();
                                }
                                bounds.extend(marcadores[i].getPosition());
                                this.markerSelecionado = marcadores[i];                                                                  
                            }
                            break;
                        }
                    }
                }
                
                if (bounds !== null) {
                    this.map.fitBounds(bounds);
                    this.map.panToBounds(bounds);
                }

                if (this.infowindow != null) {
                    this.infowindow.close();
                    this.infowindow = null;
                    this.markerInfowindow = null;
                }

            }
            if (this.filteredVeiculos.length == 1 && this.markerSelecionado != null && this.markerInfowindow != null) {
                window.google.maps.event.trigger(markerSelecionado, 'click');
            }
        },      
    },
    mounted() {
        this.map = new window.google.maps.Map(document.getElementById('map'), {
            center: new window.google.maps.LatLng(-5, -42),       
            gestureHandling: 'greedy',
            zoom: 8,
            fullscreenControl:true,
            fullscreenControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_TOP            
            },
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_TOP
            },
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU
            }
        });
        this.fetchListaClientes();
    },
    beforeDestroy() {
        clearInterval(this.pollingIntervalObj);
    }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 160px);
      position: relative;      
    } 
  .tabelaComScroll2 {      
      overflow: auto;  
      height: calc(100vh - 400px);
      position: relative;      
    }  
</style>